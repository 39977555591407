import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import RichContentBlock from "../components/richContentBlock"
import TitleBlock from "../components/titleBlock"
import BenefitsBlock from "../components/benefitsBlock"
import { getPagePath } from "../utils/path"
import StoriesBlock from "../components/storiesBlock"
import CustomTabs from "../components/customTabs"
import ImageGallery from "../components/imageGallery"
import CtaBlock from "../components/ctaBlock"

const Page = ({ data: { page, site }, pageContext }) => {
  const locale = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(l => {
    return {
      locale: l.locale,
      value: getPagePath(page, l.locale),
    }
  })

  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <SEO title={page.title} lang={locale} />
      <Header title={page.title} locale={locale} />
      <Content>
        {page.content.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "cta_block" && (
              <CtaBlock content={section} />
            )}
            {section.model.apiKey === "image_gallery" && (
              <ImageGallery images={section.images} />
            )}
            {section.model.apiKey === "rich_content_block" && (
              <RichContentBlock content={section} />
            )}
            {section.model.apiKey === "title_block" && (
              <TitleBlock content={section} />
            )}
            {section.model.apiKey === "benefits_block" && (
              <BenefitsBlock content={section} />
            )}
            {section.model.apiKey === "stories_block" && (
              <StoriesBlock content={section} locale={locale} />
            )}
            {section.model.apiKey === "tab_block" && (
              <CustomTabs tabs={section.richContent} kind="richContent" />
            )}
          </section>
        ))}
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  /* margin: ${DesignTokens.spacing[6]} 0 0; */
`

export default Page

export const query = graphql`
  query PageQuery($slug: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      _allSlugLocales {
        value
        locale
      }
      content {
        ... on DatoCmsCtaBlock {
          id
          backgroundColor {
            hex
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          ...GalleryDetails
        }
        ... on DatoCmsTabBlock {
          richContent {
            label
            title
            text
            video
            alignment
            benefits {
              ... on DatoCmsBenefit {
                id
                model {
                  apiKey
                }
                title
                text
                icon {
                  url
                  alt
                }
              }
            }
            callToAction {
              ... on DatoCmsLink {
                ...LinkDetails
              }
              ... on DatoCmsExternalLink {
                id
                model {
                  apiKey
                }
                anchorText
                url
                blank
              }
            }
            backgroundColor {
              hex
            }
            icon {
              url
              alt
            }
            image {
              url
              alt
            }
            backgroundImage {
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesBlock {
          title
          label
          text
          id
          stories {
            ... on DatoCmsStory {
              id
              model {
                apiKey
              }
              title
              country
              companyName
              slug
              meta {
                publishedAt(locale: "it")
              }
              heroImage {
                sizes(imgixParams: { fit: "crop", w: "350", h: "260" }) {
                  base64
                  width
                  height
                  src
                  srcSet
                  sizes
                }
                alt
                url
              }
            }
          }
          callToAction {
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleBlock {
          title
          label
          text
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBenefitsBlock {
          title
          label
          layout
          id
          backgroundColor {
            hex
          }
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentBlock {
          label
          title
          text
          alignment
          video
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          backgroundColor {
            hex
          }
          icon {
            url
            alt
          }
          image {
            url
            alt
          }
          backgroundImage {
            url
          }
          id
          model {
            apiKey
          }
        }
      }
    }
  }
`
